<template>
    <el-row>
        <!-- 服饰名称 -->
        <el-col :span="12">
            <div>服饰名称</div>
        </el-col>
        <el-col :span="12">
            <div class="lease_check_order_detail_rt">{{order_detail.goods_name}}</div>
        </el-col>
        <!-- 商家名称 -->
        <el-col :span="12">
          <div>出借人</div>
        </el-col>
        <el-col :span="12">
          <div class="lease_check_order_detail_rt">{{order_detail.owner}}</div>
        </el-col>
      <!-- 商家电话 -->
      <el-col :span="12">
        <div>出借人电话</div>
      </el-col>
      <el-col :span="12">
        <div class="lease_check_order_detail_rt">{{order_detail.ownerPhone}}</div>
      </el-col>
      <!-- 物流单号 -->
      <el-col :span="12">
        <div>物流单号</div>
      </el-col>
      <el-col :span="12">
        <div class="lease_check_order_detail_rt">{{order_detail.trackingNo}}</div>
      </el-col>
    </el-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: mapState({
        order_detail: state => state.order.order_detail,
    }),
}
</script>

<style scoped>
/* 订单详情右侧内容 */
.lease_check_order_detail_rt{
    text-align: right;
}
</style>
